<template>
    <el-row>
        <el-row class="project-text">
            <p>在芜湖app是一款可以让用户更加便捷轻松的生活在芜湖的服务类软件，在这里用户可以轻松了解最新的楼盘信息，想买房的朋友就方便了，还能了解最新的美食购物资讯，吃货们有福了，另外其他的一些服务就不一一例举，朋友们下载到自己手机上亲自体验吧</p>
            <p>在芜湖是一款集热点聚焦、吃在芜湖、游遍芜湖、时尚生活、购物中心、房产楼市、教育培训、芜湖车市、人才招聘、便民工具等为一体的手机客户端。全面展示芜湖当地的热点聚焦、吃在芜湖、游遍芜湖、时尚生活、购物中心、房产楼市、教育培训、芜湖车市、人才招聘、便民工具等相关城市信息。</p>
            <p>功能特色</p>
            <p>1.内容全面丰富，涵盖芜湖吃、住、游、购、娱等信息。<br/>
                2.界面颜色清新，给用户一种清爽舒适之感!<br/>
                3.整洁：排版先后有序，分布简洁明了;<br/>
                4.专业：定位芜湖，信息服务全面;<br/>
                5.免费下载应用程序，用户免费注册登录;<br/>
                6.在线留言评论功能，实现沟通交流无障;</p>
        </el-row>
        <el-row class="project-img row-flex" type="flex" justify="space-between">
            <el-col class="img-item" :span="11"><img src="@/assets/images/projects/project_1/AtWuhu-1.jpg" alt=""></el-col>
            <el-col class="img-item" :span="11"><img src="@/assets/images/projects/project_1/AtWuhu-2.jpg" alt=""></el-col>
            <el-col class="img-item" :span="11"><img src="@/assets/images/projects/project_1/AtWuhu-3.jpg" alt=""></el-col>
            <el-col class="img-item" :span="11"><img src="@/assets/images/projects/project_1/AtWuhu-4.jpg" alt=""></el-col>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "WuHu"
    }
</script>

<style scoped>
    .project-text {
        color: #797979;
        line-height: 1.5;
        font-size: 14px;
    }

    .project-img img {
        width: 100%;
    }

    .project-img>.img-item {
        margin-top: 15px;
    }
</style>
