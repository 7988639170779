<template>
    <el-row class="desc">
        <el-row class="project-desc">
            <p>信用芜湖</p>
        </el-row>
        <el-row class="project-img row-flex" type="flex" justify="space-between">
            <el-col class="img-item" :md="11"><img src="@/assets/images/projects/creditwuhu/credit-wuhu-1.jpg" alt=""></el-col>
            <el-col class="img-item" :md="11"><img src="@/assets/images/projects/creditwuhu/credit-wuhu-2.jpg" alt=""></el-col>
            <el-col class="img-item" :md="11"><img src="@/assets/images/projects/creditwuhu/credit-wuhu-3.jpg" alt=""></el-col>
            <el-col class="img-item" :md="11"><img src="@/assets/images/projects/creditwuhu/credit-wuhu-4.jpg" alt=""></el-col>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "CreditWuHu"
    }
</script>

<style scoped>
    .project-img img {
        width: 100%;
    }

    .project-img>.img-item {
        margin-top: 15px;
    }
</style>
