<template>
    <el-row>
<!--        <keep-alive>-->
<!--            <component :is="currentView"></component>-->
<!--        </keep-alive>-->
        <wu-hu v-show="currentId === '1'"></wu-hu>
        <shop v-show="currentId === '2'"></shop>
        <credit-wu-hu v-show="currentId === '3'"></credit-wu-hu>
        <trip v-show="currentId === '4'"></trip>
        <smart-site v-show="currentId === '5'"></smart-site>
    </el-row>
</template>

<script>
    import WuHu from "./WuHu";
    import Shop from "./XyShop";
    import CreditWuHu from "./CreditWuHu";
    import Trip from "./HxTrip";
    import SmartSite from "./SmartSite";
    export default {
        name: "ProjectDetail",
        data(){
          return {
              currentView: 'WuHu',
              currentId: '1',
              viewMap: new Map([['1', 'WuHu'], ['2', 'Shop'], ['3', 'CreditWuHu'], ['4', 'Trip']])
          }
        },
        components: {
            WuHu,
            Shop,
            CreditWuHu,
            Trip,
            SmartSite
        },
        watch: {
            $route(newVal){
                this.switchView(newVal.params.id)
            }
        },
        methods: {
            switchView(id){
                this.currentId = id
                this.currentView = this.viewMap.get(id)
            }
        }
    }
</script>

<style scoped>

</style>
