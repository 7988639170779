<template>
    <el-row class="hx-trip desc">
        <el-row>
            <p>好行网</p>
        </el-row>
        <el-row>
            <img src="@/assets/images/projects/trip/trip.jpg" alt="">
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "HxTrip"
    }
</script>

<style scoped>
.hx-trip img {
    width: 100%;
}

</style>
