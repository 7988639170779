<template>
    <el-row class="desc shop">
        <el-row>
            <p>欣源商贸</p>
        </el-row>
        <el-row>
            <el-col>
                <img src="@/assets/images/projects/shop/xyshop.jpg" alt="">
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "XyShop"
    }
</script>

<style scoped>
.shop img {
    width: 100%;
}
</style>
