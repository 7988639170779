<template>
    <el-row class="desc smart-site">
        <el-row>
            <p>智慧工地管控平台</p>
        </el-row>
        <el-row>
            <el-col>
                <img src="@/assets/images/projects/smart/smart-site.png" alt="">
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "SmartSite"
    }
</script>

<style scoped>
    .smart-site img {
        width: 100%;
    }
</style>
